.loading-bar-container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 2px;
  margin: 20px 0;
}

.loading-bar {
  height: 4px;
  width: 0%;
  background-color: #007bff;
  border-radius: 2px;
  animation: load 2s infinite ease-out;
}

@keyframes load {
  0% {
    width: 0%;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 100%;
  }
}
